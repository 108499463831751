<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>wedding-money-piggy</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.107,9.75,13.724,5.178a2.6,2.6,0,0,1-.486-2.994h0a2.593,2.593,0,0,1,4.153-.674l.716.716.716-.716a2.593,2.593,0,0,1,4.153.674h0a2.594,2.594,0,0,1-.486,2.994Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.5,10.125a.375.375,0,0,1,.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.125,10.5a.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.5,10.875a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.875,10.5a.375.375,0,0,1-.375.375"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="6.011"
      y1="20.204"
      x2="5.25"
      y2="23.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="16.49"
      y1="20.208"
      x2="17.25"
      y2="23.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.019,5.516,7.054,3.879a.749.749,0,0,0-1.224.483L5.479,7.173A8.206,8.206,0,0,0,2.6,11.25H.75v6H3.24a9.114,9.114,0,0,0,8.01,4.5c4.971,0,9-3.694,9-8.25a7.444,7.444,0,0,0-.037-.75"
    />
  </svg>
</template>
